import $ from "jquery";

const isDesktopSize = () => {
  if (document.getElementById("semicon-header")) {
    if (window.innerWidth >= 1200) {
      return true;
    }
  } else if (window.innerWidth >= 992) {
    return true;
  }

  return false;
};

const getElementOrDie = (selector: string): HTMLElement => {
  const el = document.querySelector<HTMLElement>(selector);
  if (el == null)
    throw new Error(`Unable to get required element: ${selector}`);
  return el;
};

/**
 * string constants
 */
const jsToggleNav: string = ".js-toggle-nav";
const jsSubnav: string = ".js-subnav";
const jsToggleableNav: string = ".js-toggleable-nav";
const jsNavbarButtons: string = ".js-navbar-buttons";
const jsMainNav: string = ".js-main-nav";

export default class Navigation {
  private mainContainer: HTMLElement;

  private navbarButtonContainer: HTMLElement;

  private toggleNavSelector: string = jsToggleNav;

  private isActive: boolean = false;

  private showListeners: { (): void }[];

  private hideListeners: { (): void }[];

  constructor() {
    this.mainContainer = getElementOrDie(jsToggleableNav);
    this.navbarButtonContainer = getElementOrDie(jsNavbarButtons);
    this.isActive = false;
    this.setViewMode();
    this.addEventListeners();

    this.showListeners = [];
    this.hideListeners = [];
  }

  setViewMode() {
    if (isDesktopSize()) {
      this.slideNavOnMouseEnter();
      this.setDesktopView();
    } else {
      this.setMobileView();
    }
  }

  closeSubnav() {
    $(jsToggleNav).addClass("collapsed");
    $(jsSubnav).removeClass("show");
    this.hideNavModal();
    this.hideNavbarButtons();
    $(this.mainContainer).removeClass("slide-out");
    $(".js-open-subnav").removeClass("active");
    $(jsMainNav).removeClass("active");
  }

  show() {
    if (this.isActive) return;
    $(this.toggleNavSelector).removeClass("collapsed");
    $("body").addClass("noscroll");
    $(".js-toggleable-nav").addClass("show");
    $(jsNavbarButtons).addClass("show");
    this.navMobileBreadcrumb();

    this.callListenersShow();
    this.isActive = true;
  }

  hide() {
    if (!this.isActive) return;
    $(this.toggleNavSelector).addClass("collapsed");
    $("body").removeClass("noscroll");
    $(".js-toggleable-nav").removeClass("show");
    $(jsNavbarButtons).removeClass("show");
    // Reset slide state of main navbar slide
    this.setSlideOutMainNav(false);
    this.resetSlideOutState();
    // Hide modal of currently active nav item
    $(jsSubnav).removeClass("show");
    this.navMobileBreadcrumb();

    this.callListenersHide();

    this.isActive = false;
  }

  toggle() {
    if (this.isActive) this.hide();
    else this.show();
  }

  // eslint-disable-next-line class-methods-use-this
  navMobileBreadcrumb() {
    const mobNavOpen = document.querySelector(jsToggleNav);
    if (
      mobNavOpen?.classList.contains("collapsed") ||
      window.innerWidth >= 992
    ) {
      return;
    }

    const currentURL = window.location.pathname;
    const navLinkMain = document.getElementsByClassName("nav-link-main-js");
    for (const navLinkMainItem of navLinkMain) {
      if (
        navLinkMainItem.getAttribute("href") !== null &&
        !currentURL.includes(navLinkMainItem.getAttribute("href") ?? "")
      ) {
        continue;
      }
      this.setSlideOutMainNav(true);
      const el = navLinkMainItem.nextElementSibling?.nextElementSibling;
      if (!el?.classList.contains("js-subnav")) {
        continue;
      }

      el?.classList.add("show");
      const navLinkMainChild = document.getElementsByClassName(
        "nav-link-main-child-js"
      );

      for (const navLinkMainChildItem of navLinkMainChild) {
        if (
          navLinkMainChildItem.getAttribute("href") !== null &&
          !currentURL.includes(navLinkMainChildItem.getAttribute("href") ?? "")
        ) {
          continue;
        }

        navLinkMainChildItem
          .closest(".js-open-subnav")
          ?.classList.add("active");
        navLinkMainChildItem
          .closest(".subnav-item")
          ?.closest(".js-navbar-slide")
          ?.classList.add("slide-out");
        const navLinkMainChildChild = document.getElementsByClassName(
          "nav-link-main-child-child-js"
        );
        for (const navLinkMainChildChildItem of navLinkMainChildChild) {
          if (
            navLinkMainChildChildItem.getAttribute("href") !== null &&
            !currentURL.includes(
              navLinkMainChildChildItem.getAttribute("href") ?? ""
            )
          ) {
            continue;
          }

          navLinkMainChildChildItem
            .closest(".js-open-subnav")
            ?.classList.add("active");
          navLinkMainChildChildItem
            .closest(".js-navbar-slide")
            ?.classList.add("slide-out");
        }
      }
    }
  }

  setMobileView() {
    $(this.mainContainer).removeClass("slide-out");
  }

  setDesktopView() {
    $(this.mainContainer).addClass("slide-out");
  }

  // eslint-disable-next-line class-methods-use-this
  showMainNavElement(element: Element) {
    element.classList.add("open");
  }

  // eslint-disable-next-line class-methods-use-this
  hideMainNavElement(element: Element) {
    element.classList.remove("open");
  }

  slideNavOnMouseEnter() {
    let mouseenterTimeout: number | undefined;
    const timeoutEnter = 150;

    document.querySelectorAll(jsMainNav).forEach((el) => {
      el.addEventListener("mouseenter", () => {
        if (!isDesktopSize()) {
          return;
        }

        // @ts-expect-error expects to return type NodeJS.Timeout, but is actually a number
        mouseenterTimeout = setTimeout(() => {
          this.showMainNavElement(el);
        }, timeoutEnter);
      });

      el.addEventListener("mouseleave", () => {
        if (!isDesktopSize()) {
          return;
        }
        clearTimeout(mouseenterTimeout);
        this.hideMainNavElement(el);
      });

      const closeButton = el.querySelectorAll(".js-subnav-close");
      closeButton.forEach((btn) => {
        btn.addEventListener("click", () => {
          this.hideMainNavElement(el);
        });
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setSlideOut(el: HTMLElement, state: boolean) {
    if (state) el.classList.add("slide-out");
    else el.classList.remove("slide-out");
  }

  setSlideOutMainNav(state: boolean) {
    this.setSlideOut(this.mainContainer, state);
    this.setSlideOut(this.navbarButtonContainer, state);
  }

  resetSlideOutState() {
    this.mainContainer.querySelectorAll(".slide-out").forEach((el) => {
      el.classList.remove("slide-out");
    });
    this.setSlideOutMainNav(false);
  }

  addEventListeners() {
    document
      .querySelectorAll<HTMLElement>(this.toggleNavSelector)
      .forEach((el) => {
        el.addEventListener("click", () => {
          this.toggle();
        });
      });

    $(".nav-link-mobile").on("click", (ev) => {
      const targetName = $(ev.target).closest(jsMainNav).data("nav-target");
      const targetModal = $(`#${targetName}NavModal`);
      if (targetModal.length === 0) {
        return;
      }
      targetModal.addClass("show");
      this.setSlideOutMainNav(true);
    });

    // display nav on mouse enter
    $(jsSubnav).on("mouseenter", function () {
      $(this).closest(jsMainNav).addClass("active");
    });

    // hide nav on mouse leave
    $(jsSubnav).on("mouseleave", function () {
      $(this).closest(jsMainNav).removeClass("active");
    });

    $(".js-main-nav-return").on("click", (ev) => {
      ev.stopPropagation();
      const target = $(ev.target).data("nav-target");
      $(ev.target)
        .closest(`.js-main-nav[data-nav-target='${target}']`)
        .removeClass("active");
      $(`#${target}NavModal`).toggleClass("show");
      this.setSlideOutMainNav(false);
    });

    $(".js-nav-return").on("click", function (ev) {
      ev.stopPropagation();
      $(this)
        .parent()
        .closest(".js-navbar-slide.slide-out")
        .removeClass("slide-out");
      $(this).closest(".js-open-subnav").removeClass("active");
    });

    $(".js-open-subnav").on("click", function (ev) {
      $(this).addClass("active");
      $(this).closest(".js-navbar-slide").addClass("slide-out");
      ev.stopPropagation();
      // disable siblings and descendants when activated
      $(this)
        .siblings()
        .each(function () {
          $(this).removeClass("active");
          $(this).find(".js-open-subnav").removeClass("active");
        });
      $(this).find(".js-open-subnav").removeClass("active");
      $(this).closest(".subnav-container").scrollTop(0);
    });

    window.addEventListener("resize", () => {
      this.setViewMode();
    });

    $(".js-close-subnav").click(() => {
      this.closeSubnav();
    });
  }

  /**
   * Add a function to be executed when the search bar is activated
   * @param func a callable function. Receives no arguments.
   */
  onShow(func: () => void) {
    this.showListeners.push(func);
  }

  /**
   * Add a function to be executed when the search bar is activated
   * @param func a callable function. Receives no arguments.
   */
  onHide(func: () => void) {
    this.hideListeners.push(func);
  }

  callListenersShow() {
    this.showListeners.forEach((func) => {
      func();
    });
  }

  callListenersHide() {
    this.hideListeners.forEach((func) => {
      func();
    });
  }

  showNavModal() {
    this.mainContainer.classList.add("show");
  }

  hideNavModal() {
    this.mainContainer.classList.remove("show");
  }

  showNavbarButtons() {
    this.navbarButtonContainer.classList.add("show");
  }

  hideNavbarButtons() {
    this.navbarButtonContainer.classList.remove("show");
  }
}
