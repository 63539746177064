export default class SearchBar {
  private navbarSearchIcons: NodeListOf<HTMLElement>;

  private navbarSearchIconsToggle: NodeListOf<HTMLElement>;

  private navbarSearchIconsShow: NodeListOf<HTMLElement>;

  private navbarSearchIconsHide: NodeListOf<HTMLElement>;

  private navbarInputContainerMobile: HTMLElement | null;

  private navbarInputContainerDesktop: HTMLElement | null;

  private navbarSearchInputFieldMobile: HTMLInputElement | null;

  private navbarSearchInputFieldDesktop: HTMLInputElement | null;

  private isActive: boolean = false;

  private hideDelayMs = 200;

  private showDelayMs = 100;

  private locked = false;

  private showListeners: { (): void }[];

  private hideListeners: { (): void }[];

  constructor() {
    this.navbarSearchIcons = document.querySelectorAll(
      ".js-navbar-search-icon"
    );
    this.navbarSearchIconsToggle = document.querySelectorAll(
      ".js-navbar-search-icon--toggle"
    );
    this.navbarSearchIconsShow = document.querySelectorAll(
      ".js-navbar-search-icon--show"
    );
    this.navbarSearchIconsHide = document.querySelectorAll(
      ".js-navbar-search-icon--hide"
    );

    this.navbarInputContainerMobile = document.querySelector<HTMLElement>(
      ".js-navbar-search-input--mobile"
    );
    this.navbarInputContainerDesktop = document.querySelector<HTMLElement>(
      ".js-navbar-search-input--desktop"
    );

    this.navbarSearchInputFieldMobile = this.navbarInputContainerMobile
      ? this.navbarInputContainerMobile.querySelector("input")
      : null;
    this.navbarSearchInputFieldDesktop = this.navbarInputContainerDesktop
      ? this.navbarInputContainerDesktop.querySelector("input")
      : null;

    this.showListeners = [];
    this.hideListeners = [];

    this.addEventListeners();
  }

  lock() {
    if (this.locked) return false;
    this.locked = true;
    return this.locked;
  }

  unlock(delayMs: number = 0) {
    if (delayMs > 0) {
      setTimeout(() => {
        this.locked = false;
      }, delayMs);
    } else {
      this.locked = false;
    }
  }

  show() {
    if (!this.lock()) return;
    // this.navbarSearchInputField?.focus();
    this.navbarInputContainerDesktop?.classList.add("show");
    this.navbarInputContainerMobile?.classList.add("show");
    this.navbarSearchInputFieldDesktop?.focus();
    this.navbarSearchInputFieldMobile?.focus();

    //  add class hint for items to consume, e.g. change appearance or hide
    this.navbarSearchIcons.forEach((element) => {
      element.classList.add("rt-nav-search--active");
    });
    this.isActive = true;
    this.callListenersShow();
    this.unlock(this.showDelayMs);
  }

  hide() {
    if (!this.lock()) return;
    this.navbarInputContainerDesktop?.classList.remove("show");
    this.navbarInputContainerMobile?.classList.remove("show");
    this.isActive = false;

    //  remove class hint
    this.navbarSearchIcons.forEach((element) => {
      element.classList.remove("rt-nav-search--active");
    });
    this.callListenersHide();
    this.unlock(this.hideDelayMs);
  }

  toggle() {
    if (this.isActive) this.hide();
    else this.show();
  }

  addEventListeners() {
    this.navbarSearchIconsToggle.forEach((element) => {
      // Slide out search input
      element.addEventListener("click", () => {
        this.toggle();
      });
    });

    this.navbarSearchIconsShow.forEach((element) => {
      element.addEventListener("click", () => {
        this.show();
      });
    });

    this.navbarSearchIconsHide.forEach((element) => {
      element.addEventListener("click", () => {
        this.hide();
      });
    });
  }

  /**
   * Add a function to be executed when the search bar is activated
   * @param func
   */
  onShow(func: () => void) {
    this.showListeners.push(func);
  }

  onHide(func: () => void) {
    this.hideListeners.push(func);
  }

  callListenersShow() {
    this.showListeners.forEach((func) => {
      func();
    });
  }

  callListenersHide() {
    this.hideListeners.forEach((func) => {
      func();
    });
  }
}
