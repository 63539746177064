import { onDomReady } from "../../utils/on-dom-ready";

const handleFade = (
  scrollElement: HTMLElement,
  topElement: HTMLElement | null,
  bottomElement: HTMLElement | null
) => {
  const height = scrollElement.scrollHeight - scrollElement.clientHeight;
  const scroll = scrollElement.scrollTop;

  if (topElement != null) {
    if (scroll === 0) {
      topElement.classList.remove("visible");
    } else {
      topElement.classList.add("visible");
    }
  }

  if (bottomElement != null) {
    if (scroll === height) {
      bottomElement.classList.remove("visible");
    } else {
      bottomElement.classList.add("visible");
    }
  }
};

const navigationScrolled = (
  event: Event,
  topElement: HTMLElement | null,
  bottomElement: HTMLElement | null
) => {
  handleFade(event.target as HTMLElement, topElement, bottomElement);
};

onDomReady(() => {
  const navigationList = document.querySelector<HTMLElement>(".navbar-nav");
  const topElement = document.querySelector<HTMLElement>(".navbar__fade--top");
  const bottomElement = document.querySelector<HTMLElement>(
    ".navbar__fade--bottom"
  );

  if (navigationList == null) {
    return;
  }

  navigationList.addEventListener("scroll", (event) =>
    navigationScrolled(event, topElement, bottomElement)
  );
});
